.button {
  @apply inline-block font-sans text-lg font-bold whitespace-normal rounded;
  @apply px-5 py-3 border-2;
}

.center {
  @apply mx-auto;
}

.has-width {
  & :global(.btn) {
    @apply w-full whitespace-normal text-center;
  }
}

.width-25 {
  @apply w-1/4;
}

.width-50 {
  @apply w-1/2;
}

.width-75 {
  @apply w-3/4;
}

.width-100 {
  @apply w-full;
}

.multiple-wrap {
  @apply mb-6;
  &:last-of-type {
    @apply mb-0;
  }

  &.width-25 {
    width: calc(25% - 0.75em);
  }

  &.width-50 {
    width: calc(50% - 0.75em);
  }

  &.width-75 {
    width: calc(75% - 0.75em);
  }
}
